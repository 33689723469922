

































































import { FiltroDeBuscaDeMetas,  Usuario } from '@/models';
import { Operador } from '@/models/venda/Operador';
import { obterFiltroDeBuscaDeMetasDefault } from '@/shareds/meta/meta-shareds';
import { nextTick } from '@/shareds/utils'
import { Vue, Component, Ref, Prop} from 'vue-property-decorator';
import BuscaDeVendedor from './BuscaDeVendedor.vue';
import DialogoDeBuscaDeVendedor from './DialogoDeBuscaDeVendedor.vue';
import SeletorDeVendedor from './SeletorDeVendedor.vue';

const ULTIMO_FILTRO_DE_META = 'ULTIMO_FILTRO_DE_META'



@Component({
	components: {
		DialogoDeBuscaDeVendedor,
		BuscaDeVendedor,
		SeletorDeVendedor,
	},
})

export default class MenuDeFiltroDeMetas extends Vue {
@Ref() campo!: HTMLInputElement
@Prop() idLoja!: string
@Prop() descricao!: string


filtro = localStorage[ULTIMO_FILTRO_DE_META]
	? JSON.parse(localStorage[ULTIMO_FILTRO_DE_META])
	: obterFiltroDeBuscaDeMetasDefault

usuario: Usuario[] = []
operador: Operador | null = null

mostra: boolean | null = null

carregando = false
cancelToken: any;


async filtrar() {
	await nextTick()
	const filtrosNormalizados: FiltroDeBuscaDeMetas = {
		valor: this.filtro.valor ? this.filtro.valor : undefined,
		idVendedorAtribuido: this.operador ? this.operador.id : null,
		descricao: this.descricao? this.descricao : null,
		lojaId: this.idLoja ? this.idLoja : null, 
	}
	this.$emit('confirmar', filtrosNormalizados)
	this.mostra = false
}

limparFiltros() {
	this.filtro = obterDefault()
	this.operador = null
	this.aplicarFiltros()
}

aplicarFiltros() {
	localStorage[ULTIMO_FILTRO_DE_META] = JSON.stringify(this.filtro)	
}

}

function obterDefault(): FiltroDeBuscaDeMetas {
	return  {
		idVendedorAtribuido: null,
		descricao: null,
		valor: null,
		lojaId: '',
	}
}
