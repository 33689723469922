









































































import { Vue, Component, Prop, PropSync, Ref } from 'vue-property-decorator'
import { FindMetasUseCase } from '@/usecases';
import { Meta } from '@/models';
import { DeleteMetaUseCase } from '@/usecases/meta/DeleteMetaUseCase';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import Confirmacao from '../ui/Confirmacao.vue';



@Component({
	components: {
		Confirmacao,
		
	},
})
export default class ListaDeMetas extends Vue {
	@PropSync('value') selecionado!: Meta| null
	@Prop({ type: Array, default: () => []}) metas!: Meta[]
	@Prop({ type: Function }) to?: (meta: Meta) => void
	@Ref() confirmacao!: Confirmacao
	

	metaSelecionada: Meta | null = null
	escolhido: Meta | null = null
	meta: Meta | null = null
	findMetasUseCase = new FindMetasUseCase()
	deleteUseCase = new DeleteMetaUseCase()
	
	excluindo = false
	atualizar = false
	
	async excluirMeta() {
		if (!this.metaSelecionada) return
		try {
			this.excluindo = true
			await this.deleteUseCase.delete(this.metaSelecionada.id)
			AlertModule.setSuccess('Meta Deletada')
		} catch (error: any) {
			this.selecionado = null
			AlertModule.setError(error)
		} finally {
			this.excluindo = false
			this.$emit('atualizar')
		}
	}

	async escolher(meta: Meta) {
		this.escolhido = meta
	}

	async exibeConfirmacao(meta: Meta) {
		this.metaSelecionada = meta
		this.confirmacao.mostrar()
	}

	get mostrarEncurtado() {
		return this.$vuetify.breakpoint.xs || this.$route.name !== 'Metas'
	}
	
}
