

















































import { Vue, Component, Prop, Watch, PropSync, Ref } from 'vue-property-decorator'
import { FindUsuarioUseCase } from '@/usecases'
import { Page, Pageable, Usuario } from '@/models'
import axios, { CancelTokenSource } from 'axios'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component
export default class SeletorDeVendedor extends Vue {
	@Ref() campo!: HTMLInputElement
	@PropSync('value') selecionado?: Usuario | Usuario[] | string | string[]
	@Prop({type: Array, default: () => []}) excludes!: string[]
	@Prop({ type: String }) idDaLojaSalva!: string | null
	@Prop({ type: String }) usuario?: string
	@Prop({ type: Boolean, default: false }) filtrarSomenteUsuariosAtivos!: boolean
	@Prop({ type: Boolean, default: false }) filtrarSomenteVendedorAtivo!: boolean
	
	usuarios: Usuario[] = []
	busca: string | null = null
	findUsuarioUseCase = new FindUsuarioUseCase()
	cancelToken: CancelTokenSource | null = null

	pageable: Pageable = {
		page: 0,
		size: 3,
	}
	
	pagina: Page<Usuario> | null = null
	carregando = true

	created() {
		this.carregarSelecao()
	}

	async carregarSelecao() {
		if (!this.selecionado) {
			return this.find()
		}
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			this.pagina = await this.findUsuarioUseCase.findAllVendedores({
				somenteVendedor:  this.filtrarSomenteVendedorAtivo,
				somenteUsuariosAtivos: this.filtrarSomenteUsuariosAtivos,
				page: this.pageable.page || 0,
				size: this.pageable.size,
			}, axiosConfig)
			this.usuarios = this.pagina.content
			
		} catch (error: any) {
			if (axios.isCancel(error)) return
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}


	async find() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			} 
			this.pagina = await this.findUsuarioUseCase.findAllVendedores({
				somenteVendedor:  this.filtrarSomenteVendedorAtivo,
				somenteUsuariosAtivos: this.filtrarSomenteUsuariosAtivos,
				page: this.pageable.page || 0,
				size: this.pageable.size,
			}, axiosConfig)
			this.usuarios = this.pagina.content
			this.carregando = false
		} catch (error: any) {
			if (axios.isCancel(error)) return
			this.pagina = null
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	@Watch('busca', { deep: true })
	onChangeBusca(busca: string, buscaAnterior: string) {
		this.pageable = {
			...this.pageable,
			page: 0,
		}
		if (busca === buscaAnterior) {
			this.carregando = false
			return
		}
		this.find()
	}
	

	async pageNext() {
		this.pageable.page = !this.pageable.page
			? 1
			: this.pageable.page += 1

		await this.find()
	}

	
	async pageReturn() {
		this.pageable.page = !this.pageable.page
			? -1
			: this.pageable.page -= 1

		await this.find()
	}


	get usuariosFiltrados() {
		return this.usuarios
	}

	get carregouTodosOsUsuarios() {
		return this.pagina?.last || false
	}

	get noDataMessage() {
		if (this.carregando) {
			return 'Carregando...'
		}
		if (!this.busca) {
			return 'Digite para consultar...'
		}
		return '$vuetify.noDataText'
	}

	focus() {
		this.campo.focus()
	}

}
