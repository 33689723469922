























































































import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator'
import { FindMetasUseCase } from '@/usecases/meta'
import ListaDeMetas from '@/components/metas/ListaDeMetas.vue'
import TelaGenerica from '@/components/layout/TelaGenerica.vue'
import { MetaModule } from '@/store/vuex/meta/MetaStore'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import axios, { CancelTokenSource } from 'axios'
import CheckboxButton from '@/components/ui/CheckboxButton.vue'
import MenuDeFiltroDeMetas from '@/components/metas/MenuDeFiltroDeMetas.vue'
import { FiltroDeBuscaDeMetas, Meta } from '@/models/Meta'
import { Page } from '@/models/Page'
import { Pageable, Usuario } from '@/models'
import { FindUsuarioUseCase } from '@/usecases'

import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'

const FILTRO_DE_METAS = 'FILTRO_DE_METAS'

@Component ({
	components: {
		TelaGenerica,
		ListaDeMetas,
		CheckboxButton,
		MenuDeFiltroDeMetas,
		SeletorDeLojasDoUsuario,
	},

})
export default class TelaDeMetas extends Vue {
	@PropSync('value') selecionado!: Meta | null
	@Prop({ type: Function }) to?: (meta: Meta) => void
	@Ref() listaDeMetas!: ListaDeMetas
	@Ref() filtroMeta!: MenuDeFiltroDeMetas
	@Ref() form!: HTMLFormElement
	
	findMetasUseCase = new FindMetasUseCase()
	findUsuarioUseCase = new FindUsuarioUseCase()

	usuario: Usuario | null = null
	cancelToken: CancelTokenSource | null = null
	pagina: Page<Meta> | null = null
	filtroAplicado: FiltroDeBuscaDeMetas = localStorage[FILTRO_DE_METAS]
		? JSON.parse(localStorage[FILTRO_DE_METAS])
		: {
			idVendedorAtribuido: null,
			descricao: null,
			valor: null,
			lojaId: '',
		}
	idLoja: string | null = null
	busca= ''
	page = 1
	totalPages = 1
	carregando = false
	edicaoAberta = false

	filtrar(filtros: FiltroDeBuscaDeMetas) {
		this.filtroAplicado = {
			...filtros,
		}
		this.buscarMetas()
	}
	
	
	async buscarMetas(paginavel: Pageable = {page: 0, size: 40}) {
		try{
			this.carregando = true 
			if(!this.filtroAplicado.lojaId) return
			if (this.cancelToken) this.cancelToken.cancel()
			this.cancelToken = axios.CancelToken.source()
		
			const params = {
				...this.filtroAplicado,
				...paginavel,
			}
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			this.pagina = await this.findMetasUseCase.findMetas(params, axiosConfig)
			MetaModule.setMetas(this.pagina.content)
		} catch (error: any) {
			if(axios.isCancel(error)) return
			this.pagina= null
			AlertModule.setError(error)
		} finally{
			this.carregando = false
		}
	}

	abrirEdicao(){
		this.$router.push({ name: 'Nova Meta' })
		this.edicaoAberta = true
	}

	get metasFiltradas(){
		return MetaModule.metasFiltradas
	}

	get filtro(){
		return MetaModule.filtrar
	}

	@Watch('filtro')
	onChangeFiltro(newFiltro) {
		const stringified = JSON.stringify(newFiltro);
		localStorage.setItem(FILTRO_DE_METAS, stringified)
		this.buscarMetas()
		MetaModule.atualizarFiltro(false)
	}

	@Watch('filtrar', { deep: true })
	onChangeFiltrar(newFiltro) {
		const stringified = JSON.stringify(newFiltro);
		localStorage.setItem(FILTRO_DE_METAS, stringified)
		this.buscarMetas()
	}

	@Watch('page')
	onChangePage = this.buscarMetas

	created() {
		this.filtroMeta.limparFiltros()
		this.filtroAplicado.lojaId = null
	}
}
